import axios from 'axios';
import { APIResult } from 'core/model/contractors-api/APIResult';

type ApiRequestResult = {
  success: boolean;
  code?: string;
  statusCode: number;
};

export class ContractAPI<T> {
  private url: string;
  private params?: {};
  private member_id?: string;
  private response?: T;
  private code?: string;

  constructor(init: { path: string; params?: {}; member_id?: string }) {
    this.url = `${process.env.REACT_APP_CONTRACTORS_API}${init.path}`;
    this.params = init.params;
    this.member_id = init.member_id;
  }

  setParams = (params: {}) => {
    this.params = params;
  };

  getResponse = () => {
    return this.response;
  };

  getCode = () => {
    return this.code;
  };

  private receivableStatus = (status: number) => {
    return [200, 401].includes(status);
  };

  /**
   * GETメソッドでAPIリクエスト
   * @returns 指定したデータ型
   */
  get = async (): Promise<ApiRequestResult> => {
    let isOK = false;
    for (let i = 0; i < 5; i++) {
      const result = await axios
        .get(this.url, {
          params: { ...this.params, member_id: this.member_id },
        })
        .then((response) => {
          if ([200, 401].includes(response.status)) {
            isOK = true;
            const apiResponse = JSON.parse(response.data);
            const apiResult = apiResponse.result as APIResult;
            this.response = apiResponse.response;
            return {
              statusCode: response.status,
              success: apiResult.success,
              code: apiResult.code,
            };
          }
        })
        .catch(() => {
          return {
            statusCode: 0,
            success: false,
          };
        });
      if (isOK && result) {
        return result;
      }
    }

    return {
      success: false,
      statusCode: 0,
    };
  };

  /**
   * POSTメソッドでAPIリクエスト
   * @returns 指定したデータ型
   */
  post = async (): Promise<ApiRequestResult> => {
    let isOK = false;
    for (let i = 0; i < 5; i++) {
      const result = await axios
        .post(this.url, { ...this.params, member_id: this.member_id })
        .then((response) => {
          if ([200, 401].includes(response.status)) {
            isOK = true;
            const apiResponse = JSON.parse(response.data);
            const apiResult = apiResponse.result as APIResult;
            this.response = apiResponse.response;
            return {
              statusCode: response.status,
              success: apiResult.success,
              code: apiResult.code,
            };
          }
        })
        .catch(() => {
          return {
            statusCode: 0,
            success: false,
          };
        });
      if (isOK && result) {
        return result;
      }
    }

    return {
      success: false,
      statusCode: 0,
    };
  };

  /**
   * PUTメソッドでAPIリクエスト
   * @returns 指定したデータ型
   */
  put = async (): Promise<ApiRequestResult> => {
    let isOK = false;
    axios.defaults.validateStatus = this.receivableStatus;
    for (let i = 0; i < 5; i++) {
      const result = await axios
        .put(this.url, { ...this.params, member_id: this.member_id })
        .then((response) => {
          if ([200, 401].includes(response.status)) {
            isOK = true;
            const apiResponse = JSON.parse(response.data);
            const apiResult = apiResponse.result as APIResult;
            this.response = apiResponse.response;
            return {
              statusCode: response.status,
              success: apiResult.success,
              code: apiResult.code,
            };
          }
        })
        .catch(() => {
          return {
            statusCode: 0,
            success: false,
          };
        });
      if (isOK && result) {
        return result;
      }
    }

    return {
      success: false,
      statusCode: 0,
    };
  };

  /**
   * DELETEメソッドでAPIリクエスト
   * @returns 指定したデータ型
   */
  delete = async (): Promise<ApiRequestResult> => {
    let isOK = false;
    for (let i = 0; i < 5; i++) {
      const result = await axios
        .delete(this.url, {
          data: { ...this.params, member_id: this.member_id },
        })
        .then((response) => {
          if ([200, 401].includes(response.status)) {
            isOK = true;
            const apiResponse = JSON.parse(response.data);
            const apiResult = apiResponse.result as APIResult;
            this.response = apiResponse.response;
            return {
              statusCode: response.status,
              success: apiResult.success,
              code: apiResult.code,
            };
          }
        })
        .catch(() => {
          return {
            statusCode: 0,
            success: false,
          };
        });
      if (isOK && result) {
        return result;
      }
    }

    return {
      success: false,
      statusCode: 0,
    };
  };
}
