import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { Banner } from 'ui/organism/Banner';
import { useCookies } from 'react-cookie';
import { CampaignView } from 'ui/organism/Campaign';
import { NoticeView } from 'ui/organism/Notice';
import { Amount } from 'ui/molecule/Amount';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import invoice from 'contents/images/icon/invoice.svg';
import yen from 'contents/images/icon/yen.svg';
import usage from 'contents/images/icon/usage.svg';
import './style.scss';
import { COST_TYPE } from 'core/const/CostType';
import { GetApiResponse } from 'core/model/contractors-api/BillingNext';
import { DISTRIBUTOR } from 'core/const/Distributor';

type NavigateState = {
  code?: string;
};

export const Home = () => {
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [member_mame, setMemberName] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [cost_type, setCostType] = useState<number>();
  const [distributor, setDistributor] = useState<number>();
  const [billingNext, setBillNext] = useState<GetApiResponse>();
  const [expired, setExpired] = useState<boolean>(false);
  const location = useLocation();
  const navigateState = location.state as NavigateState;

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
      setMemberName(cookieContract.member_name);
      setContractId(cookieContract.contract_id);
      setCostType(cookieContract.cost_type);
      setDistributor(cookieContract.distributor);
    }
  }, []);

  /**
   * 請求情報取得
   */
  useEffect(() => {
    if (member_id)
      (async () => {
        const api = new ContractAPI<GetApiResponse>({
          path: CONTRACTORS_API_PATH.BILLING_NEXT,
          params: {
            member_id,
            contract_id: contract_id,
          },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            setBillNext(response);
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
      })();
  }, [member_id]);

  return (
    <PrivateSite code={navigateState?.code} expired={expired}>
      <Container className="home-invoice">
        <Row>
          <Col>
            <div className="home-invoice__username">
              <span>{member_mame}</span> 様
            </div>

            <div className="home-invoice__transfer">
              <Container>
                {distributor === DISTRIBUTOR.OAC ? (
                  <>
                    <Row>
                      <Col xs={5}>
                        <p>次回振替金額</p>
                      </Col>
                      <Col>
                        <p>
                          <Amount amount={billingNext?.amount} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <p>次回振替日</p>
                      </Col>
                      <Col>
                        <p>{billingNext?.billing_date || '未定'}</p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={5}>
                        <p>毎月の請求金額</p>
                      </Col>
                      <Col>
                        <p>
                          <Amount amount={billingNext?.amount} />
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </div>
          </Col>
        </Row>

        {distributor === DISTRIBUTOR.OAC && (
          <Row className="home-invoice__status">
            <Col>
              <Link to="/billing">
                <p>ご請求お支払い状況はこちら</p>
              </Link>
            </Col>
          </Row>
        )}
      </Container>

      <Banner />

      <Container className="home-maintenance">
        {cost_type === COST_TYPE.ADVANCE && (
          <>
            <Row className="mb-3">
              <Col className="home-maintenance__block">
                <Link to="/maint/input">
                  <p>
                    メンテナンス
                    <br />
                    費用請求
                  </p>
                  <img src={invoice} alt="請求書" />
                  <img src={yen} alt="¥" />
                </Link>
              </Col>
              <Col className="home-maintenance__block">
                <Link to="/maint">
                  <p>
                    メンテナンス
                    <br />
                    請求状況
                  </p>
                  <img src={usage} alt="ご利用明細" />
                </Link>
              </Col>
            </Row>

            <div className="home-maintenance__link">
              <Link to="/merchant">メンテナンスプラン対応業者一覧</Link>
            </div>
          </>
        )}

        <div className="home-maintenance__link">
          <Link to="/contract">お客様情報の確認</Link>
        </div>
      </Container>

      <CampaignView />
      <NoticeView />
    </PrivateSite>
  );
};
