import { MAINT_REQUEST_STATUS } from 'core/const/MaintRequestStatus';
import React, { useEffect, useState } from 'react';

interface Props {
  status: number;
}

/**
 * メンテナンス請求ステータス
 */
export const MaintRequestStatus: React.FC<Props> = ({
  status,
}: Props): React.ReactElement => {
  const [text, setText] = useState<string>('---');
  useEffect(() => {
    switch (status) {
      case MAINT_REQUEST_STATUS.INPUT:
        setText('入力中');
        break;
      case MAINT_REQUEST_STATUS.RESEND:
        setText('要再送');
        break;
      case MAINT_REQUEST_STATUS.REQUESTED:
      case MAINT_REQUEST_STATUS.RE_REQUESTED:
        setText('請求中');
        break;
      case MAINT_REQUEST_STATUS.WORKING:
      case MAINT_REQUEST_STATUS.CS_WORKING:
      case MAINT_REQUEST_STATUS.WORK_CANCEL:
      case MAINT_REQUEST_STATUS.WORK_REJECT:
      case MAINT_REQUEST_STATUS.WAIT_APPROVE:
        setText('確認中');
        break;
      case MAINT_REQUEST_STATUS.APPROVED:
      case MAINT_REQUEST_STATUS.SECOND_APPROVED:
      case MAINT_REQUEST_STATUS.STANDBY_TRANSFER:
        setText('振込予定');
        break;
      case MAINT_REQUEST_STATUS.COMPLETE:
        setText('振込済');
        break;
      case MAINT_REQUEST_STATUS.WAIT_PAYMENT:
        setText('支払待ち');
        break;
      case MAINT_REQUEST_STATUS.CANCEL:
        setText('取下');
        break;
      case MAINT_REQUEST_STATUS.REJECT:
        setText('不受理');
        break;
      case MAINT_REQUEST_STATUS.TRANSFER_FAIL:
        setText('振込失敗');
        break;
      case MAINT_REQUEST_STATUS.ERROR:
        setText('処理中');
        break;
    }
  });
  return <span>{text}</span>;
};
