const RESULT_MESSAGE_STYLE = {
  success: 'success',
  notice: 'notice',
  warning: 'warning',
};

export const SCREEN_RESULT = {
  ParamError: {
    code: 'ER001',
    message: 'システムエラーが発生しました。操作をやり直してください。',
    style: RESULT_MESSAGE_STYLE.warning,
  },

  Logout: {
    code: 'SS002',
    message: 'ログアウトしました。',
    style: RESULT_MESSAGE_STYLE.notice,
  },

  SessionError: {
    code: 'SS901',
    message:
      '前回のログインから一定時間経過しました。再度ログインをしてください。',
    style: RESULT_MESSAGE_STYLE.warning,
  },

  LostSessionError: {
    code: 'SS902',
    message: 'ログイン情報がありません。再度ログインしてください。',
    style: RESULT_MESSAGE_STYLE.warning,
  },

  PasswordChanged: {
    code: 'PW001',
    message: 'パスワードの変更が完了しました。',
    style: RESULT_MESSAGE_STYLE.success,
  },

  MaintRequestCreateError: {
    code: 'MT901',
    message:
      'メンテナンス費用請求に失敗しました。お手数ですがもう一度操作してください。',
    style: RESULT_MESSAGE_STYLE.success,
  },

  MaintRequestUpdateError: {
    code: 'MT902',
    message:
      'メンテナンス費用請求の更新に失敗しました。お手数ですがもう一度操作してください。',
    style: RESULT_MESSAGE_STYLE.success,
  },
};
