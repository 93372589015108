import React, { useState } from 'react';
import { Container, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import './style.scss';

interface Props {
  selectedFiles: File[];
  setFiles: (files: File[]) => void;
}

type ReceiptModal = {
  isShow: boolean;
  receipt: File | null;
};

/**
 * ファイル操作
 */
export const ImageControl: React.FC<Props> = ({
  selectedFiles,
  setFiles,
}): React.ReactElement => {
  const [receiptModal, setReceiptModal] = useState<ReceiptModal>({
    isShow: false,
    receipt: null,
  });
  const [message, setMessage] = useState<string>();
  const max_recipt = parseInt(process.env.REACT_APP_MAX_RECEPT_FILES || '5');

  /**
   * 選択したファイルをリストに追加する
   * @param event
   */
  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }
    const targetFile = Object.values(event.target.files).concat()[0];

    if (selectedFiles.length >= max_recipt) {
      setMessage(`明細書は${max_recipt}枚以上アップロードできません。`);
      return;
    }
    if (!checkFile(targetFile, selectedFiles)) {
      setMessage('同じファイルは複数アップロードできません。');
      return;
    }
    const concatFiles = selectedFiles.concat(targetFile);
    setFiles(concatFiles);
  };

  /**
   * リストから画像を削除する
   * @param index
   */
  const deleteHandle = (index: number) => {
    const concatFiles = selectedFiles.concat();
    concatFiles.splice(index, 1);
    setFiles(concatFiles);
    setMessage(undefined);
  };

  /**
   * リストの画像を更新する
   * @param index
   * @param event
   */
  const modifyHandle = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }
    const targetFile = Object.values(event.target.files).concat()[0];
    if (!checkFile(targetFile, selectedFiles)) {
      setMessage('同じファイルは複数アップロードできません。');
      return;
    }
    const concatFiles = selectedFiles.concat();
    setFiles(concatFiles);
  };

  /**
   * ファイルのチェック
   * @param file
   * @param files
   * @returns
   */
  const checkFile = (file: File, files: File[]): boolean => {
    const ableContentType = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
    ];
    if (!ableContentType.includes(file.type)) {
      return false;
    }
    const isSameSize = files.some((row) => row.size === file.size);
    if (isSameSize) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Container className="image-control">
        {selectedFiles.length > 0 && (
          <>
            {selectedFiles.map((row, index) => {
              return (
                <Row className="mb-2" key={`image-control-${index}`}>
                  <Col className="image-control__left">
                    <label>
                      <img
                        src={URL.createObjectURL(row)}
                        alt={`明細書${index}`}
                      />
                      <Button
                        onClick={() =>
                          setReceiptModal({ isShow: true, receipt: row })
                        }
                      />
                    </label>
                  </Col>
                  <Col className="image-control__right">
                    <div className="sheet-number">{index + 1}枚目</div>
                    <div className="button-frame">
                      <label>
                        <p className="delete-button">削除</p>
                        <Form.Control onClick={() => deleteHandle(index)} />
                      </label>
                    </div>
                    <div className="button-frame">
                      <label>
                        <p className="modify-button">変更</p>
                        <Form.Control
                          type="file"
                          name="files"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => modifyHandle(index, event)}
                        />
                      </label>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </>
        )}

        {selectedFiles.length < max_recipt ? (
          <>
            <Row>
              <Col className="image-control__add">
                <label>
                  <p>画像ファイルを追加</p>
                  <Form.Control
                    type="file"
                    name="files"
                    onChange={handleFile}
                  />
                </label>
              </Col>
            </Row>

            {message && (
              <Row>
                <Col>
                  <p className="image-control__message">※{message}</p>
                </Col>
              </Row>
            )}
          </>
        ) : (
          <Row>
            <Col>
              <p className="image-control__message">
                一度の請求でアップロードできるファイルは{max_recipt}枚です。
                <br />
                別のファイルをアップロードする場合は他のファイルを削除してください。
              </p>
            </Col>
          </Row>
        )}
      </Container>

      {/** 明細書画像の全画面表示モーダル */}
      <Modal
        show={receiptModal.isShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setReceiptModal({ isShow: false, receipt: null })}
        className="image-control-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {receiptModal.receipt && (
            <img src={URL.createObjectURL(receiptModal.receipt)} alt="明細書" />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
