import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ContractAmount } from 'core/model/contract/ContractAmount';
import { Amount } from 'ui/molecule/Amount';

interface Props {
  amounts: ContractAmount[];
}

/**
 * 料金内訳
 */
export const AmountSummary: React.FC<Props> = ({
  amounts,
}): React.ReactElement => {
  const [lease_amount, setLeaseAmount] = useState<number>(0);
  const [support_amount, setSupportAmount] = useState<number>(0);
  const [maint_amount, setMaintAmount] = useState<number>(0);

  useEffect(() => {
    let lease_amount = 0;
    let support_amount = 0;
    let maint_amount = 0;
    amounts.forEach((row) => {
      const amount = Number(row.amount);
      if (row.revenue_type === 0) {
        lease_amount += amount;
      } else if (row.revenue_type === 1 || row.revenue_type === 2) {
        support_amount += amount;
      } else if (row.revenue_type === 3) {
        maint_amount += amount;
      }
    });
    setLeaseAmount(lease_amount);
    setSupportAmount(support_amount);
    setMaintAmount(maint_amount);
  });

  return (
    <Container>
      <Row>
        <Col>
          <dl>
            <dt>内訳</dt>
            <dd>
              {lease_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">リース料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={lease_amount} />
                    </p>
                  </Col>
                </Row>
              )}

              {support_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">カーライフサポート料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={support_amount} />
                    </p>
                  </Col>
                </Row>
              )}

              {maint_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">メンテナンスプラン料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={maint_amount} />
                    </p>
                  </Col>
                </Row>
              )}
            </dd>
          </dl>
        </Col>
      </Row>
    </Container>
  );
};
