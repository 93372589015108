import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractBank } from 'core/model/contract/ContractBank';
import { Badge, Col, Container, Form, Row } from 'react-bootstrap';
import { FormikProps } from 'formik';
import { ContractBankView } from 'ui/organism/ContractBank';
import './style.scss';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
}

/**
 * 口座情報の表示
 * ・OACスキームは必ずDBの口座を使用する
 * ・その他のスキームは都度入力か前回口座かを選択する
 *
 */
export const ContractBankForm: React.FC<Props> = ({
  formik,
}): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [bankInfo, setBankInfo] = useState<ContractBank>();
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  /**
   * 銀行情報の取得
   */
  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<ContractBank>({
          path: CONTRACTORS_API_PATH.BANK,
          params: {
            member_id,
            contract_id,
          },
          member_id,
        });
        if (await api.get()) {
          const bank = api.getResponse();
          if (bank) {
            setBankInfo(bank);
          }
        }
      })();
    }
  }, [member_id]);

  return (
    <>
      {bankInfo && (
        <Container className="registered-check">
          <Row>
            <Col>
              <label>
                <Form.Control
                  type="checkbox"
                  onChange={() =>
                    formik.setFieldValue(
                      'is_registered_bank',
                      !formik.values.is_registered_bank,
                    )
                  }
                />
                <p>前回の口座を使用する</p>
              </label>
            </Col>
          </Row>
        </Container>
      )}

      {formik.values.is_registered_bank && bankInfo ? (
        <ContractBankView />
      ) : (
        <Container className="bank-form">
          <Row>
            <Col>
              <div className="headline">お振込口座情報</div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <Badge bg="danger">必須</Badge> 金融機関名
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="〇〇銀行"
                  {...formik.getFieldProps('bank_name')}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <Badge bg="danger">必須</Badge> 支店名
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="〇〇支店"
                  {...formik.getFieldProps('branch_name')}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <Badge bg="danger">必須</Badge> 預金種目
                </Form.Label>
                <Form.Select {...formik.getFieldProps('branch_name')}>
                  <option value="1">普通</option>
                  <option value="2">当座</option>
                  <option value="4">貯蓄</option>
                  <option value="9">その他</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <Badge bg="danger">必須</Badge> 口座名義人
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ｶﾙﾓﾀﾛｳ"
                  {...formik.getFieldProps('account_holder')}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <Badge bg="danger">必須</Badge> 口座番号
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="1234567"
                  {...formik.getFieldProps('account_number')}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
