import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { MaintRequest } from 'core/model/contract/MaintRequest';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { PrivateSite } from 'ui/template/PrivateSite';
import './style.scss';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { GetApiResponse } from 'core/model/contractors-api/MaintRequest';
import { MaintRequestStatus } from 'ui/molecule/MaintRequestStatus';

type NavigateState = {
  code?: string;
};

export const MaintRequestTop = () => {
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [requests, setRequests] = useState<MaintRequest[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<GetApiResponse[]>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: { member_id, contract_id },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            const tmp_requests: MaintRequest[] = [];
            response.forEach((row) => {
              tmp_requests.push(row.request);
            });
            setRequests(tmp_requests);
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
        setLoaded(true);
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite
      title="メンテナンス請求状況"
      code={navigateState?.code}
      expired={expired}
    >
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>
              {format(new Date(), 'yyyy/MM/dd')}
              時点の請求状況をご確認いただけます。
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="mb-3">
        <Row>
          <Col>
            <div className="headline">ご請求一覧</div>
          </Col>
        </Row>

        {!loaded ? (
          <PartLoadingView />
        ) : (
          <>
            {requests.length > 0 ? (
              <Row>
                <Col>
                  <Table className="maint-request-list">
                    <thead>
                      <tr>
                        <td>ご請求日</td>
                        <td>申請状況</td>
                        <td>詳細</td>
                      </tr>
                    </thead>
                    <tbody>
                      {requests.map((request, index) => {
                        return (
                          <tr key={`maint-request-${index}`}>
                            <td>{request.request_date}</td>
                            <td>
                              <MaintRequestStatus status={request.status} />
                            </td>
                            <td>
                              <Link to={`/maint/detail/${request.request_id}`}>
                                確認
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <p>メンテナンス請求はありません。</p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </PrivateSite>
  );
};
