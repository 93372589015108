export const MAINT_REQUEST_STATUS = {
  INPUT: 0,
  RESEND: 1,
  REQUESTED: 10,
  RE_REQUESTED: 11,
  WORKING: 20,
  CS_WORKING: 21,
  WORK_CANCEL: 25,
  WORK_REJECT: 26,
  WAIT_APPROVE: 30,
  WAIT_PAYMENT: 31,
  APPROVED: 40,
  SECOND_APPROVED: 41,
  STANDBY_TRANSFER: 50,
  COMPLETE: 60,
  CANCEL: 80,
  REJECT: 81,
  TRANSFER_FAIL: 91,
  ERROR: 99,
};
