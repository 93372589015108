interface Dict {
  [key: number]: {
    value: string;
    is_alert?: boolean;
  };
}

export const BillingStatus: Dict = {
  0: { value: '予定' },
  10: { value: '確認中' },
  40: { value: '済' },
  41: { value: 'カルモ分済' },
};
