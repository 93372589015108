import { format } from 'date-fns';
import imageCompression from 'browser-image-compression';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { BucketUrl } from 'core/model/contract/BucketUrl';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import axios from 'axios';

interface Props {
  files: File[];
  member_id: string;
}

/**
 * 所定のs3にファイルをアップロードする
 * @param files
 * @returns 格納先のファイルパス
 */
export const uploadToS3 = async ({
  files,
  member_id,
}: Props): Promise<string[]> => {
  // 格納先のパス
  const filePaths: string[] = [];

  // バケット情報の取得
  const bucket = process.env.REACT_APP_S3_BUCKET || '';
  const dir = process.env.REACT_APP_S3_RECEIPT_DIR || '';

  // 画像の圧縮
  const compressdImages = await Promise.all(
    files.map(async (row) => {
      const sysdate = format(new Date(), 'yyyyMMddHHmmss');
      return {
        blob: await imageCompression(row, { maxSizeMB: 3 }),
        name: `${sysdate}_${row.name}`,
      };
    }),
  );

  for (const image of compressdImages) {
    const file_path = `${dir}/${image.name}`;

    // アップロード先の署名付きURLを取得
    const api = new ContractAPI<BucketUrl>({
      path: CONTRACTORS_API_PATH.BUCKET,
      params: { member_id, bucket, file_path, expire: 3600 },
      member_id,
    });
    if (await api.get()) {
      const response = api.getResponse();
      if (response) {
        const blobImage = await image.blob.arrayBuffer();
        const put = await axios
          .put(response.puturl, blobImage)
          .then((response) => {
            if (response.status === 200) {
              return true;
            }
          })
          .catch(() => {
            return false;
          });
        if (put) {
          filePaths.push(file_path);
        }
      }
    } else {
      continue;
    }
  }
  return filePaths;
};
