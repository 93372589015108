import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { useFormik } from 'formik';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PrivateSite } from 'ui/template/PrivateSite';
import * as yup from 'yup';
import './style.scss';
import { useEffect, useState } from 'react';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { useCookies } from 'react-cookie';
import { encrypt } from 'compornents/functions/util/Crypt';
import { format } from 'date-fns';
import { PutApiResponse } from 'core/model/contractors-api/APIResult';
import { RESULT_CODE } from 'core/const/ResultCode';

export const PasswordSetting: React.FC = () => {
  const navigate = useNavigate();
  const [member_id, setMemberId] = useState<string>();
  const [cookies] = useCookies(['contract']);
  const [expired, setExpired] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
    }
  }, []);

  const onSubmit = async (formInputs: {}) => {
    setLoading(true);
    const params = JSON.parse(JSON.stringify(formInputs));
    const auth = [
      params.old_password,
      params.new_password,
      format(new Date(), 'yyyyMMddHHmmss'),
    ];
    const api = new ContractAPI<PutApiResponse>({
      path: CONTRACTORS_API_PATH.MEMBER_SESSION,
      params: {
        auth: encrypt(
          auth.join(','),
          process.env.REACT_APP_CRYPT_API || '',
          process.env.REACT_APP_CRYPT_SALT || '',
          process.env.REACT_APP_CRYPT_IV || '',
        ),
      },
      member_id,
    });
    const result = await api.put();
    console.log(result);
    if (result.success && result.statusCode === 200) {
      navigate('/home', {
        state: { code: RESULT_CODE.PASSWORD_CHANGED },
      });
    } else if (result.statusCode === 401) {
      setExpired(true);
    } else {
      if (result.code === 'E003') {
        setCode(RESULT_CODE.UNMATCH_PASSWORD);
      } else if (result.code === 'E004') {
        setCode(RESULT_CODE.SAME_PASSWORD);
      } else {
        setCode(RESULT_CODE.PASSWORD_CHANGE_FAILED);
      }
    }
    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    old_password: yup.string().required('必須項目です。'),
    new_password: yup
      .string()
      .min(8, '8文字以上で入力してください。')
      .matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください。')
      .required('必須項目です。'),
    new_password_confirm: yup
      .string()
      .min(8, '8文字以上で入力してください。')
      .matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください。')
      .required('必須項目です。')
      .oneOf([yup.ref('new_password'), ''], '新しいパスワードが一致しません。'),
  });

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      new_password_confirm: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <PrivateSite
      title="パスワード再設定"
      code={code}
      loading={loading}
      expired={expired}
    >
      <Container className="mb-3">
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="old_password">
                  <Form.Label>現在のパスワード</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="****"
                    isInvalid={
                      !!formik.touched.old_password &&
                      !!formik.errors.old_password
                    }
                    {...formik.getFieldProps('old_password')}
                  />
                  {formik.touched.old_password && formik.errors.old_password ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.old_password}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="new_password">
                  <Form.Label>新しいパスワード</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="****"
                    isInvalid={
                      !!formik.touched.new_password &&
                      !!formik.errors.new_password
                    }
                    {...formik.getFieldProps('new_password')}
                  />
                  {formik.touched.new_password && formik.errors.new_password ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.new_password}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="new_password_confirm">
                  <Form.Label>新しいパスワード（確認用）</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="****"
                    isInvalid={
                      !!formik.touched.new_password_confirm &&
                      !!formik.errors.new_password_confirm
                    }
                    {...formik.getFieldProps('new_password_confirm')}
                  />
                  {formik.touched.new_password_confirm &&
                  formik.errors.new_password_confirm ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.new_password_confirm}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="text-center">
                  <Button type="submit">パスワードを変更</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </Container>
    </PrivateSite>
  );
};
