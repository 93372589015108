import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { MaintRequest } from 'core/model/contract/MaintRequest';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { BucketUrl } from 'core/model/contract/BucketUrl';
import { PrivateSite } from 'ui/template/PrivateSite';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { ContractBankView } from 'ui/organism/ContractBank';
import { Amount } from 'ui/molecule/Amount';
import './style.scss';
import { GetApiResponse } from 'core/model/contractors-api/MaintRequest';
import { MaintRequestReceipt } from 'core/model/contract/MaintRequestReceipt';
import { MaintRequestItem } from 'core/model/contract/MaintRequestItem';
import { MAINT_REQUEST_STATUS } from 'core/const/MaintRequestStatus';

type ReceiptModal = {
  isShow: boolean;
  receiptUrl: string | null;
};

export const MaintRequestDetail = () => {
  const { request_id } = useParams();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [request, setRequest] = useState<MaintRequest>();
  const [items, setItems] = useState<MaintRequestItem[]>([]);
  const [receipts, setReceipts] = useState<MaintRequestReceipt[]>([]);
  const [receiptUrls, setReceiptUrls] = useState<string[]>([]);
  const bucket = process.env.REACT_APP_S3_BUCKET || '';
  const [receiptModal, setReceiptModal] = useState<ReceiptModal>({
    isShow: false,
    receiptUrl: null,
  });
  const [totalPaymentAmount, setTotalPaymentAmount] = useState<number>(0);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<GetApiResponse[]>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: { member_id, contract_id, request_id },
          member_id,
        });

        if (await api.get()) {
          const response = api.getResponse();
          if (response && response.length === 1) {
            setRequest(response[0].request);
            setItems(response[0].items);
            setReceipts(response[0].receipts);
          }
        }
      })();
    }
  }, [member_id]);

  useEffect(() => {
    if (receipts.length > 0) {
      (async () => {
        const t_receiptUrls: string[] = [];
        for await (const receipt of receipts) {
          const api = new ContractAPI<BucketUrl>({
            path: CONTRACTORS_API_PATH.BUCKET,
            params: {
              member_id,
              bucket,
              file_path: receipt.file_path,
              expire: 3600,
            },
            member_id,
          });
          if (await api.get()) {
            const bucket = api.getResponse();
            if (bucket) {
              t_receiptUrls.push(bucket.geturl);
            }
          }
        }
        setReceiptUrls(t_receiptUrls);
      })();
    }
  }, [receipts]);

  useEffect(() => {
    if (items.length > 0) {
      const total = items.reduce((total, item) => {
        const paymentAmount =
          item.payment_amount !== null && item.payment_amount !== undefined
            ? Number(item.payment_amount)
            : 0;
        return total + paymentAmount;
      }, 0);
      setTotalPaymentAmount(total);
    }
  }, [items]);

  return (
    <>
      <PrivateSite title="メンテナンス費用請求">
        <Container className="maint-detail">
          {request && request.status < MAINT_REQUEST_STATUS.WORKING && (
            <Row className="maint-detail__action">
              <Col>
                <Link to={`/maint/cancel/${request_id}`}>取下</Link>
              </Col>
              <Col>
                <Link to={`/maint/edit/${request_id}`}>編集</Link>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <div className="headline">{request?.request_date} ご請求分</div>
            </Col>
          </Row>

          <Row>
            <Col>
              {!request ? (
                <PartLoadingView />
              ) : (
                <dl>
                  <dt>メンテナンス実施日</dt>
                  <dd>{request.use_date}</dd>
                  <dt>振込日</dt>
                  <dd>{request.payment_date || <>未定</>}</dd>
                  <dt>振込合計金額</dt>
                  <dd>
                    {totalPaymentAmount !== 0 ? (
                      <Amount amount={totalPaymentAmount} />
                    ) : (
                      '未定'
                    )}
                  </dd>
                  {request.return_reason && (
                    <>
                      <dt>差戻理由</dt>
                      <dd>{request.return_reason}</dd>
                    </>
                  )}
                </dl>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="headline">メンテナンス項目</div>
            </Col>
          </Row>

          {items.length === 0 ? (
            <PartLoadingView />
          ) : (
            <Row>
              <Col>
                <Table className="maint-detail__item">
                  <thead>
                    <tr>
                      <td>項目名</td>
                      <td>振込金額</td>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((row, index) => {
                      return (
                        <tr key={`maint-item-${index}`}>
                          <td>{row.item_name}</td>
                          <td>
                            {row.payment_amount !== null &&
                            row.payment_amount !== undefined ? (
                              <Amount amount={row.payment_amount} />
                            ) : (
                              '未定'
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <div className="headline">明細写真</div>
            </Col>
          </Row>

          {!receiptUrls ? (
            <PartLoadingView />
          ) : (
            <Row xs={2} className="maint-detail__receipt">
              {receiptUrls.map((row, index) => {
                return (
                  <Col key={`receipt-${index}`} className="mb-2">
                    <label>
                      <img src={row} alt={`receipt-${index}`} />
                      <Button
                        onClick={() =>
                          setReceiptModal({
                            isShow: true,
                            receiptUrl: row,
                          })
                        }
                      />
                    </label>
                  </Col>
                );
              })}
            </Row>
          )}
        </Container>

        {request && (
          <ContractBankView
            bank_props={{
              bank_name: request.bank_name,
              branch_name: request.branch_name,
              deposit_code: request.deposit_code,
              account_holder: request.account_holder,
              account_number_mask: request.account_number_mask,
            }}
          />
        )}

        <Container>
          <Row className="maint-detail__backward">
            <Col>
              <Link to="/maint">請求一覧に戻る</Link>
            </Col>
          </Row>
        </Container>
      </PrivateSite>

      {/** 明細書写真の全画面表示モーダル */}
      <Modal
        show={receiptModal.isShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setReceiptModal({ isShow: false, receiptUrl: null })}
        className="transparent"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {receiptModal.receiptUrl && (
            <img src={receiptModal.receiptUrl} alt="明細書" />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
