export const CONTRACTORS_API_PATH = {
  AUTH: 'auth',
  AUTH_LOGIN: 'auth/login',
  AUTH_LOGOUT: 'auth/logout',
  BANK: 'bank',
  BILLING: 'billing',
  BILLING_NEXT: 'billing/next',
  BUCKET: 'bucket',
  CAMPAIGN: 'campaign',
  CONTRACT: 'contract',
  CONTRACT_DETAIL: 'contract/detail',
  CONTRACT_MAINT: 'contract/maint',
  CONTRACT_MAINT_MERCAHNT: 'contract/merchant',
  MAINT_HISTORY: 'maint/history',
  MAINT_HISTORY_ITEM: 'maint/history/item',
  MAINT_MERCAHNT: 'maint/merchant',
  MAINT_REQUEST: 'maint/request',
  MAINT_USER: 'maint/user',
  NOTICE: 'notice',
  MEMBER: 'member',
  MEMBER_SESSION: 'member/session',
  MEMBER_SESSION_RESET: 'member/session/reset',
};
