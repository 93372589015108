import CryptoJS from 'crypto-js';

/**
 * 暗号化
 * @param plain string
 * @param passphrase string
 * @param salt string
 * @param iv string
 * @returns 暗号化文字列
 */
export const encrypt = (
  plain: string,
  passphrase: string,
  salt: string,
  iv: string
): any => {
  const key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    iterations: 100,
    keySize: 8,
  });
  const cipher = CryptoJS.AES.encrypt(plain, key, {
    iv: CryptoJS.enc.Utf8.parse(iv),
  });
  return CryptoJS.enc.Base64.stringify(cipher.ciphertext);
};

/**
 * 復号化
 * @param cipher string
 * @param passphrase string
 * @param salt string
 * @param iv string
 * @returns プレーンテキスト
 */
export const decrypt = (
  cipher: string,
  passphrase: string,
  salt: string,
  iv: string
): string => {
  const key = CryptoJS.PBKDF2(passphrase, salt, {
    hasher: CryptoJS.algo.SHA512,
    iterations: 100,
    keySize: 8,
  });
  const plain = CryptoJS.AES.decrypt(cipher, key, {
    iv: CryptoJS.enc.Utf8.parse(iv),
  }).toString(CryptoJS.enc.Utf8);
  return plain;
};
