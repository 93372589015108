import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScrollTop } from 'ui/molecule/ScrollTop';
import { Login } from 'ui/pages/Login';
import { Home } from 'ui/pages/Home';
import { ContractConfirm } from 'ui/pages/Contract';
import { BillingConfirm } from 'ui/pages/Billing';
import { MaintRequestTop } from 'ui/pages/Maint';
import { MaintRequestDetail } from 'ui/pages/Maint/Detail';
import { MaintRequestInput } from 'ui/pages/Maint/Request';
import { MaintRequestConfirm } from 'ui/pages/Maint/Request/Confirm';
import { MaintRequestExecute } from 'ui/pages/Maint/Request/Execute';
import { MaintRequestResult } from 'ui/pages/Maint/Request/Result';
import { MaintRequestCancel } from 'ui/pages/Maint/Request/Cancel';
import { MaintRequestEdit } from 'ui/pages/Maint/Request/Edit';
import { MaintHistoryPage } from 'ui/pages/Maint/History';
import { MaintHistoryDetail } from 'ui/pages/Maint/History/Detail';
import { MaintMerchantPage } from 'ui/pages/Maint/Merchant';
import { ContractMaintMerchantPage } from 'ui/pages/Contract/Merchant';
import { CampaignPage } from 'ui/pages/Campaign';
import { NoticePage } from 'ui/pages/Notice';
import { PasswordSetting } from 'ui/pages/Password/Setting';
import { PasswordReset } from 'ui/pages/Password/Reset';
import { PasswordResetResult } from 'ui/pages/Password/Reset/Result';
import { PasswordResetSetting } from 'ui/pages/Password/Reset/Setting';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

function App() {
  return (
    <BrowserRouter basename="/app">
      <ScrollTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contract" element={<ContractConfirm />} />
        <Route
          path="/contract/merchant"
          element={<ContractMaintMerchantPage />}
        />
        <Route path="/billing" element={<BillingConfirm />} />
        <Route path="/maint" element={<MaintRequestTop />} />
        <Route path="/maint/input" element={<MaintRequestInput />} />
        <Route path="/maint/confirm" element={<MaintRequestConfirm />} />
        <Route path="/maint/execute" element={<MaintRequestExecute />} />
        <Route path="/maint/result" element={<MaintRequestResult />} />
        <Route
          path="/maint/detail/:request_id"
          element={<MaintRequestDetail />}
        />
        <Route
          path="/maint/cancel/:request_id"
          element={<MaintRequestCancel />}
        />
        <Route path="/maint/edit/:request_id" element={<MaintRequestEdit />} />
        <Route path="/maint/history" element={<MaintHistoryPage />} />
        <Route
          path="/maint/history/detail/:maint_history_id"
          element={<MaintHistoryDetail />}
        />
        <Route path="/merchant" element={<MaintMerchantPage />} />
        <Route path="/campaign" element={<CampaignPage />} />
        <Route path="/notice" element={<NoticePage />} />
        <Route path="/password/setting" element={<PasswordSetting />} />
        <Route path="/reset" element={<PasswordReset />} />
        <Route path="/reset/result" element={<PasswordResetResult />} />
        <Route path="/reset/a/:reset_uuid" element={<PasswordResetSetting />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
