import React, { ReactNode, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { ResultMessageView } from 'ui/organism/ResultMessage';
import { Header } from 'ui/organism/Header';
import { FullLoadingView } from 'ui/organism/Loading/Full';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import './style.scss';
import { SCREEN_RESULT } from 'core/enum/ScreenResult';
import { RESULT_CODE } from 'core/const/ResultCode';

interface Props {
  title?: string;
  code?: string;
  loading?: boolean;
  children?: ReactNode;
  backward_state?: {};
  expired?: boolean;
}

export const PrivateSite: React.FC<Props> = ({
  title,
  code,
  loading,
  children,
  backward_state,
  expired,
}: Props): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies(['contract']);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (!cookieContract) {
      navigate('/login', {
        state: {
          code: RESULT_CODE.LOST_SESSION,
          referrer: location.pathname,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (expired) {
      navigate('/login', {
        state: {
          code: RESULT_CODE.SESSION_ERROR,
          referrer: location.pathname,
        },
      });
    }
  }, [expired]);

  return (
    <>
      {/** ヘッダー表示（画面上部に固定） */}
      <Header backward_state={backward_state} />

      {title && (
        <Container className="private-site__title">
          <h1>{title}</h1>
        </Container>
      )}

      {code && <ResultMessageView code={code} />}

      {/** コンテンツ表示（メイン） */}
      <div>{children}</div>

      {location.pathname !== '/home' && (
        <Container className="backward-link">
          <Row>
            <Col>
              <Link to="/home">TOPに戻る</Link>
            </Col>
          </Row>
        </Container>
      )}

      {/** ローディング表示（全画面にオーバーレイ） */}
      {loading && <FullLoadingView />}
    </>
  );
};
