import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Navbar, Offcanvas } from 'react-bootstrap';
import logo from 'contents/images/carmo-logo.svg';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { COST_TYPE } from 'core/const/CostType';
import './style.scss';
import { HeaderBackward } from 'ui/molecule/HeaderBackward';
import { DISTRIBUTOR } from 'core/const/Distributor';

interface Props {
  backward_state?: {};
}

export const Header: React.FC<Props> = ({ backward_state }) => {
  const [cookies] = useCookies(['contract']);
  const [cost_type, setCostType] = useState<number>();
  const [distributor, setDistributor] = useState<number>();

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setCostType(cookieContract.cost_type);
      setDistributor(cookieContract.distributor);
    }
  }, []);

  return (
    <>
      <Navbar bg="light" sticky="top" expand={false}>
        <Navbar.Brand className="header__backward">
          <HeaderBackward state={backward_state} />
        </Navbar.Brand>

        <Navbar.Brand className="header__logo">
          <img src={logo} alt="おトクにマイカー 定額カルモくん" />
        </Navbar.Brand>

        <Navbar.Brand className="header__toggle">
          <Navbar.Toggle aria-controls="navbar-offcanvas" />
        </Navbar.Brand>

        <Navbar.Offcanvas id="navbar-offcanvas" placement="end">
          <Offcanvas.Header closeButton className="global-menu__header">
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ListGroup className="global-menu__list">
              {cost_type === COST_TYPE.ADVANCE ? (
                <>
                  <Link to="/maint/input">
                    <ListGroup.Item>メンテナンス費用請求</ListGroup.Item>
                  </Link>
                  <Link to="/maint">
                    <ListGroup.Item>メンテナンス請求状況</ListGroup.Item>
                  </Link>
                  <Link to="/merchant">
                    <ListGroup.Item>
                      メンテナンスプラン対応業者一覧
                    </ListGroup.Item>
                  </Link>
                </>
              ) : (
                cost_type === COST_TYPE.NO_ADVANCE && (
                  <Link to="/contract/merchant">
                    <ListGroup.Item>メンテナンス指定工場</ListGroup.Item>
                  </Link>
                )
              )}
              <Link to="/maint/history">
                <ListGroup.Item>メンテナンス利用履歴</ListGroup.Item>
              </Link>
              {distributor === DISTRIBUTOR.OAC && (
                <Link to="/billing">
                  <ListGroup.Item>ご請求お支払い状況</ListGroup.Item>
                </Link>
              )}
              <Link to="/contract">
                <ListGroup.Item>お客様情報の確認</ListGroup.Item>
              </Link>
              <Link to="/campaign">
                <ListGroup.Item>クーポン＆キャンペーン一覧</ListGroup.Item>
              </Link>
              <Link to="/notice">
                <ListGroup.Item>お知らせ一覧</ListGroup.Item>
              </Link>
              <a href="https://nyle.co.jp/privacypolicy/" target="_blank">
                <ListGroup.Item>プライバシーポリシー</ListGroup.Item>
              </a>
              <Link to="/password/setting">
                <ListGroup.Item>パスワード再設定</ListGroup.Item>
              </Link>
            </ListGroup>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
};
