import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  state?: {};
}

/**
 * ヘッダーの戻るボタン
 * @param amount
 * @returns 日本円表記の金額
 */
export const HeaderBackward: React.FC<Props> = ({
  state,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const on_back = () => {
    console.log('戻るボタン');
    switch (location.pathname) {
      case '/maint/input':
        navigate('/home');
        break;
      case '/maint/confirm':
        navigate('/maint/input', { state });
        break;
      case '/maint/execute':
        break;
      case '/maint/result':
        navigate('/maint/input');
        break;
      default:
        navigate(-1);
        break;
    }
  };

  return (
    <a onClick={on_back}>
      <p>戻る</p>
    </a>
  );
};
