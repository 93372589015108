import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { NoticeInfo } from 'core/model/contract/NoticeInfo';
import { PrivateSite } from 'ui/template/PrivateSite';
import './style.scss';
import { PartLoadingView } from 'ui/organism/Loading/Part';

export const NoticePage: React.FC = (): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [notices, setNotices] = useState<NoticeInfo[]>([]);
  const [member_id, setMemberId] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<NoticeInfo[]>({
          path: CONTRACTORS_API_PATH.NOTICE,
          params: { limit: 5 },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            setNotices(response);
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
        setLoaded(true);
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="お知らせ" expired={expired}>
      <Container>
        {!loaded ? (
          <PartLoadingView />
        ) : (
          <>
            {notices.length > 0 ? (
              <Row className="notice__list">
                <Col>
                  <ListGroup>
                    {notices.map((row, index) => {
                      return (
                        <ListGroup.Item key={`notice-${index}`}>
                          <p>{row.publish_date}</p>
                          <p>{row.publish_text}</p>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>
            ) : (
              <Row className="notice__none">
                <Col>
                  <p>現在、お知らせはありません</p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </PrivateSite>
  );
};
