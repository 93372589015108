import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RESULT_CODE } from 'core/const/ResultCode';
import './style.scss';

interface Props {
  code: string;
}

/**
 * リザルトコードに応じたメッセージを表示する
 */
export const ResultMessageView: React.FC<Props> = ({
  code,
}: Props): React.ReactElement => {
  const [style, setStyle] = useState<string>();
  const [message, setMessage] = useState<string>();

  const MESSAGE_TYPE = {
    success: 'success',
    notice: 'notice',
    warning: 'warning',
  };

  useEffect(() => {
    switch (code) {
      case RESULT_CODE.LOGIN_MISTAKE:
        setMessage(
          'ご入力いただいたメールアドレスまたは、パスワードが間違っています。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.USER_LOCK:
        setMessage('ユーザーをロックしました。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.LOGOUT:
        setMessage('ログアウトしました。');
        setStyle(MESSAGE_TYPE.notice);
        break;
      case RESULT_CODE.SESSION_ERROR:
        setMessage(
          '前回のログインから一定時間経過したのでログアウトしました。再度ログインをしてください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.LOST_SESSION:
        setMessage('ログイン情報がありません。再度ログインしてください。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.PASSWORD_CHANGED:
      case RESULT_CODE.RESET_PASSWORD_CHANGED:
        setMessage('パスワード設定が完了しました。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.PASSWORD_CHANGE_FAILED:
      case RESULT_CODE.RESET_PASSWORD_CHANGE_FAILED:
        setMessage('パスワード設定に失敗しました。再度入力をお願いします。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.UNMATCH_PASSWORD:
        setMessage('現在のパスワードが違います。再度入力をお願いします。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.SAME_PASSWORD:
        setMessage('現在のパスワードとは違う値を設定してください。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.MAINT_REQUEST_CREATED:
      case RESULT_CODE.MAINT_REQUEST_UPDATED:
        setMessage('メンテナンス費用請求が完了しました。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.MAINT_REQUEST_CREATE_FAILED:
      case RESULT_CODE.MAINT_REQUEST_UPDATE_FAILED:
        setMessage(
          'メンテナンス費用請求に失敗しました。お手数ですがもう一度操作してください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.RESET_MAIL_SEND_FAILED:
        setMessage(
          'パスワードリセットの受付に失敗しました。お手数ですがもう一度操作してください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.RESET_VERIFY_FAILED:
        setMessage(
          'このパスワードリセットのリンクは無効です。お手数ですが再度お手続きをしてください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      default:
        break;
    }
  }, [code]);

  return (
    <>
      {message && (
        <Container>
          <Row>
            <Col>
              <div className={`result-message ${style}`}>{message}</div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
