import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { Col, Container, Row } from 'react-bootstrap';
import { GetApiResponse } from 'core/model/contractors-api/MaintRequest';
import { format } from 'date-fns';
import { BucketUrl } from 'core/model/contract/BucketUrl';

export const MaintRequestEdit = () => {
  const navigate = useNavigate();
  const { request_id } = useParams();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const bucket = process.env.REACT_APP_S3_BUCKET || '';

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<GetApiResponse[]>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: { member_id, contract_id, request_id },
          member_id,
        });

        if (await api.get()) {
          const response = api.getResponse();
          if (response && response.length === 1) {
            const item_ids: string[] = [];
            response[0].items.forEach((item) => {
              item_ids.push(String(item.contract_maint_item_id));
            });

            const receiptFiles: File[] = [];
            for await (const receipt of response[0].receipts) {
              const api = new ContractAPI<BucketUrl>({
                path: CONTRACTORS_API_PATH.BUCKET,
                params: {
                  member_id,
                  bucket,
                  file_path: receipt.file_path,
                  expire: 3600,
                },
                member_id,
              });
              if (await api.get()) {
                const response = api.getResponse();
                if (response) {
                  const splitPath = receipt.file_path.split('/');
                  const fileName = splitPath[splitPath.length - 1];
                  const obj = await fetch(response.geturl)
                    .then((response) => response.blob())
                    .then(
                      (blob) =>
                        new File([blob], fileName, { type: 'image/png' }),
                    );
                  receiptFiles.push(obj);
                }
              }
            }

            navigate('/maint/input', {
              state: {
                request_id: response[0].request.request_id,
                item_ids,
                receiptFiles,
                use_date: format(
                  new Date(response[0].request.use_date),
                  'yyyy-MM-dd',
                ),
              },
            });
          }
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="処理中" loading={true}>
      <Container>
        <Row>
          <Col>
            <p>請求を編集する準備をしています。</p>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
