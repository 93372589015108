import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PublicSite } from 'ui/template/PublicSite';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { encrypt } from 'compornents/functions/util/Crypt';
import { addDays, format } from 'date-fns';
import { encCookieContract } from 'compornents/functions/util/Cookie';
import { LostPassword } from 'ui/organism/LostPassword';
import { PutApiResponse } from 'core/model/contractors-api/Login';
import { RESULT_CODE } from 'core/const/ResultCode';

type NavigateState = {
  code?: string;
  referrer?: string;
};

export const Login: React.FC = () => {
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const navigate = useNavigate();
  const [, setCookie, removeCookie] = useCookies(['contract']);
  const [code, setCode] = useState<string | undefined>(navigateState?.code);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    removeCookie('contract');
  }, []);

  const onSubmit = async (formInputs: {}) => {
    setLoading(true);
    const params = JSON.parse(JSON.stringify(formInputs));
    const auth = [
      params.email,
      params.password,
      format(new Date(), 'yyyyMMddHHmmss'),
    ];
    const api = new ContractAPI<PutApiResponse>({
      path: CONTRACTORS_API_PATH.AUTH_LOGIN,
      params: {
        auth: encrypt(
          auth.join(','),
          process.env.REACT_APP_CRYPT_API || '',
          process.env.REACT_APP_CRYPT_SALT || '',
          process.env.REACT_APP_CRYPT_IV || '',
        ),
      },
    });

    const result = await api.put();
    if (result.success && result.statusCode === 200) {
      const response = api.getResponse();
      if (response) {
        if (response.contracts.length === 1) {
          const expires = addDays(new Date(), 1);
          const member_id = response.member_id;
          const contract = response.contracts[0];
          const enc = encCookieContract({
            member_id,
            contract_id: contract.contract_id,
            distributor: contract.distributor,
            lease_date: contract.lease_date,
            lease_amount: contract.lease_amount,
            contract_months: contract.contract_months,
            member_name: contract.member_name,
            cost_type: contract.cost_type,
          });
          setCookie('contract', enc, { expires });

          if (navigateState?.referrer) {
            navigate(navigateState.referrer);
          } else {
            navigate('/home');
          }
        } else {
          navigate('/contractors', {
            state: {
              member_id: response.member_id,
              contracts: response.contracts,
            },
          });
        }
      }
    } else {
      setCode(RESULT_CODE.LOGIN_MISTAKE);
    }
    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('メールアドレスを入力してください。')
      .required('必須項目です。'),
    password: yup.string().required('必須項目です。'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <PublicSite title="ログイン" code={code} loading={loading}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Container>
          <Row className="row__form mt-3">
            <Col>
              <Form.Group className="form-group" controlId="email">
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="abcd@carmo-kun.jp"
                  isInvalid={!!formik.touched.email && !!formik.errors.email}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="row__form">
            <Col>
              <Form.Group className="form-group" controlId="password">
                <Form.Label>パスワード</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="****"
                  isInvalid={
                    !!formik.touched.password && !!formik.errors.password
                  }
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <LostPassword />

          <Row className="row__submit-button">
            <Col>
              <Button type="submit">ログイン</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </PublicSite>
  );
};
