import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { NoticeInfo } from 'core/model/contract/NoticeInfo';
import { Link } from 'react-router-dom';
import './style.scss';
import { PartLoadingView } from '../Loading/Part';

/**
 * お知らせを表示する
 */
export const NoticeView: React.FC = (): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [notices, setNotices] = useState<NoticeInfo[]>([]);
  const [member_id, setMemberId] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<NoticeInfo[]>({
          path: CONTRACTORS_API_PATH.NOTICE,
          params: { limit: 5 },
          member_id,
        });
        if (await api.get()) {
          const notices = api.getResponse();
          if (notices) {
            setNotices(notices);
          }
        }
        setLoaded(true);
      })();
    }
  }, [member_id]);

  return (
    <Container className="home-notice">
      <Row className="home-notice__title">
        <p>お知らせ</p>
        <p>Information</p>
      </Row>

      {!loaded ? (
        <PartLoadingView />
      ) : (
        <>
          {notices.length > 0 ? (
            <>
              <Row className="home-notice__list">
                <Col>
                  <ListGroup>
                    {notices.map((row, index) => {
                      return (
                        <ListGroup.Item key={`notice-${index}`}>
                          <p>{row.publish_date}</p>
                          <p>{row.publish_text}</p>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>

              <Row className="home-notice__link">
                <Col>
                  <Link to="/notice">お知らせ一覧</Link>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="home-notice__none">
              <Col>
                <p>現在、お知らせはありません</p>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};
