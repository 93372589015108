import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { Member } from 'core/model/contract/Member';
import { Contract, ContractDetail } from 'core/model/contract/Contract';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractCustom } from 'core/model/contract/ContractCustom';
import { ContractVehicle } from 'core/model/contract/ContractVehicle';
import { MaintPlan } from 'core/model/contract/MaintPlan';
import { PrivateSite } from 'ui/template/PrivateSite';
import { ContractBankView } from 'ui/organism/ContractBank';
import './style.scss';
import { Amount } from 'ui/molecule/Amount';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { ContractAmount } from 'core/model/contract/ContractAmount';
import { AmountSummary } from 'ui/organism/AmountSummary';
import { DISTRIBUTOR } from 'core/const/Distributor';

/**
 * お客様情報確認
 *
 * @returns ReactElement
 */
export const ContractConfirm = () => {
  const [member, setMember] = useState<Member>();
  const [contract, setContract] = useState<Contract>();
  const [amounts, setAmounts] = useState<ContractAmount[]>([]);
  const [customs, setCustoms] = useState<ContractCustom[]>();
  const [vehicle, setVehicle] = useState<ContractVehicle>();
  const [maintPlan, setMaintPlan] = useState<MaintPlan>();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [distributor, setDistributor] = useState<number>();

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
      setDistributor(cookieInfo.distributor);
    }
  }, []);

  /**
   * 会員情報の取得
   */
  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<Member>({
          path: CONTRACTORS_API_PATH.MEMBER,
          params: { member_id },
          member_id,
        });
        if (await api.get()) {
          setMember(api.getResponse());
        }
      })();
    }
  }, [member_id]);

  /**
   * 契約情報の取得
   */
  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<ContractDetail>({
          path: CONTRACTORS_API_PATH.CONTRACT_DETAIL,
          params: {
            member_id,
            contract_id,
          },
          member_id,
        });
        if (await api.get()) {
          const response = api.getResponse();
          if (response) {
            setContract(response.contract);
            setAmounts(response.amount);
            setVehicle(response.vehicle);
            setCustoms(response.custom);
            setMaintPlan(response.maint_plan);
          }
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="お客様情報確認">
      <Container>
        <Row>
          <Col>
            <div className="headline">お客様情報</div>
          </Col>
        </Row>

        {!member ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>氏名</dt>
                <dd>
                  {member.last_name} {member.first_name}
                </dd>
                <dt>フリガナ</dt>
                <dd>
                  {member.last_name_kana} {member.first_name_kana}
                </dd>
                <dt>メールアドレス</dt>
                <dd>{member.email}</dd>
                <dt>電話番号</dt>
                <dd>{member.phone_number}</dd>
                <dt>携帯番号</dt>
                <dd>{member.mobile_number}</dd>
                <dt>現住所</dt>
                <dd>
                  {member.address1}
                  {member.address2}
                  {member.address3}
                  {member.address4}
                </dd>
                <dt>住民票住所</dt>
                <dd>
                  {member.certificate_address1}
                  {member.certificate_address2}
                  {member.certificate_address3}
                  {member.certificate_address4}
                </dd>
              </dl>
            </Col>
          </Row>
        )}
      </Container>

      {distributor === DISTRIBUTOR.OAC && <ContractBankView />}

      <Container>
        <Row>
          <Col>
            <div className="headline">車両情報</div>
          </Col>
        </Row>

        {!vehicle ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>メーカー</dt>
                <dd>{vehicle.maker_name}</dd>
                <dt>車種名</dt>
                <dd>{vehicle.car_name}</dd>
                <dt>グレード</dt>
                <dd>{vehicle.grade_name}</dd>
                <dt>ボディカラー</dt>
                <dd>{vehicle.body_color}</dd>

                {customs && (
                  <>
                    <dt>オプション装備</dt>
                    <dd>
                      <ListGroup>
                        {customs.map((row, index) => {
                          return (
                            <ListGroup.Item key={`custom-${index}`}>
                              <Row>
                                <Col>
                                  <p className="list">{row.product_name}</p>
                                </Col>
                                <Col>
                                  <p>{row.product_detail}</p>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </dd>
                  </>
                )}
              </dl>
            </Col>
          </Row>
        )}
      </Container>

      <Container>
        <Row>
          <Col>
            <div className="headline">契約情報</div>
          </Col>
        </Row>

        {!contract ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>契約期間</dt>
                <dd>{contract.contract_months}ヶ月</dd>
                <dt>リース開始日</dt>
                <dd>{contract.lease_date_from}</dd>
                <dt>リース終了日</dt>
                <dd>{contract.lease_date_to}</dd>

                {maintPlan && (
                  <>
                    <dt>メンテナンスプラン</dt>
                    <dd>{maintPlan.name}</dd>
                  </>
                )}
              </dl>
            </Col>
          </Row>
        )}
      </Container>

      <Container>
        <Row>
          <Col>
            <div className="headline">毎月のお支払い料金(税込)</div>
          </Col>
        </Row>

        {!contract ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <div className="amount-per-month">
                <Amount amount={contract.lease_amount} />
              </div>
            </Col>
          </Row>
        )}
      </Container>

      {amounts.length === 0 ? (
        <PartLoadingView />
      ) : (
        <AmountSummary amounts={amounts} />
      )}
    </PrivateSite>
  );
};
